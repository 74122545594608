
import * as React from 'react';
import * as assign from 'lodash/assign';
import { Link } from 'react-router-dom';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { translateBag } from '@inwink/i18n/utils/translate';
import type { Entities } from '@inwink/entities/entities';
import { styleObject } from '@inwink/utils/methods/styleobject';
import { States } from '@@services/services';
import { metadataMenuActions } from '@@services/appmetadataactions/menu';
import { AppLink, appLinkIdentifier } from '../../applink';
import { checkIfShownByDate } from '../helpers';
import type { IMenuItem } from './bloc.menuitems';
import { SubMenuItemsSection } from './bloc.submenuitems';

const BadgeDownload = React.lazy(() => import("../../badgedownload"));

const CalendarEventGenerator = React.lazy(() => import(
    "@@event/components/calendargenerator.event"
));

interface IMenuItemProps {
    menuId?: string;
    item: IMenuItem;
    itemIndex: number;
    style?: any;
    className?: string;
    onClick?: (arg: React.MouseEvent<any>) => void;
    visualConfiguration?: Entities.IVisualConfigurationTemplate;
    datacontext: Entities.IPageDataContext;
    i18nHelper: Entities.i18nHelper;
    event?: States.IEventState;
    rootwebsite?: States.IRootWebsiteState;
    community?: States.ICommunityState;
    i18n: States.i18nState;
    user: States.IAppUserState;
    location: States.ILocation;
    page: any;
    isAppHeader?: boolean;
    showSubMenuIcon?: boolean;
    tabIndex?: number;
    metaDataMenuActions?: typeof metadataMenuActions;
}

interface IMenuItemState {
    showSubMenu: boolean;
    initialized: boolean;
}

@withI18nHelper()
export class MenuItem extends React.Component<IMenuItemProps, IMenuItemState> {
    container = React.createRef<HTMLDivElement>();

    constructor(props: IMenuItemProps) {
        super(props);
        this.state = {
            showSubMenu: false,
            initialized: false
        };
    }
    
    componentDidMount(): void {
        if (!this.state.initialized){
            this.setState({ initialized : true });
        }
    }

    toggleSubMenu = (forced?: boolean) => {
        const newVal = forced !== undefined ? forced : !this.state.showSubMenu;
        this.setState(() => {
            return {
                showSubMenu: newVal
            };
        });
    };

    subItemsContent = (props) => {
        let className = "subitems";
        if (this.props.isAppHeader) {
            let eventClassName = "";
            if (this.props.rootwebsite?.websiteid) {
                eventClassName = `rootwebsite-${this.props.rootwebsite?.websiteid}`;
            } else if (this.props.community?.communityid) {
                eventClassName = `community-${this.props.community?.communityid}`;
            } else {
                eventClassName = `event-${this.props.event?.eventid}`;
            }
            className = className + " " + eventClassName + " app-header";
        }

        return <div className={className}>
            <SubMenuItemsSection
                {...this.props}
                close={() => {
                    props.onHide();
                }}
                items={this.props.item?.items}
                template={null}
                theme={null}
            />
        </div>;
    };

    renderLink(srcmenuitemclass, showByDateTitle, menuitemstyle, icon) {
        return <div className="menuitem" id={this.menuItemId()}
        >
            {this.renderLinkContent(srcmenuitemclass, showByDateTitle, menuitemstyle, icon)}
        </div>;
    }

    menuItemId() {
        const base = this.props.menuId ? this.props.menuId + "-menuitem" : "menuitem";

        if (this.props.item?.page) {
            return base + "-" + this.props.item.page;
        }
        if (this.props.item?.link) {
            const identifier = appLinkIdentifier(this.props.item.link);
            if (identifier) {
                return base + "-" + identifier;
            }
        }
        if (this.props.itemIndex) {
            return base + "-item-" + this.props.itemIndex;
        }
        
        return null;
    }

    renderLinkContent(srcmenuitemclass, showByDateTitle, menuitemstyle, icon) {
        let menuitemclass = srcmenuitemclass;

        if (this.props.item.link.action === "addEventToCalendar" || this.props.item.link.action === "addSessionsToCalendar") {
            if (!this.state.initialized) {
                return <></>;
            }

            return <>
                <React.Suspense fallback={<EmptyLink />}>
                    <CalendarEventGenerator
                        className={menuitemclass}
                        tooltip={showByDateTitle}
                        title={this.props.item.label}
                        page={this.props.page}
                        type={this.props.item.link.action}
                        style={menuitemstyle}
                        i18n={this.props.i18n}
                        event={this.props.event}
                        role={this.props.item.role}
                        icon={icon}
                    />
                </React.Suspense>
            </>;
        } if (this.props.item.link.action === "downloadBadge") {
            if (!this.state.initialized) {
                return <></>;
            }

            return <React.Suspense fallback={<EmptyLink />}>
                <BadgeDownload
                    className={menuitemclass}
                    tooltip={showByDateTitle}
                    title={this.props.item.label}
                    type={this.props.item.link.action}
                    style={menuitemstyle}
                    i18n={this.props.i18n}
                    role={this.props.item.role}
                    icon={icon}
                />
            </React.Suspense>;
        }

        const isCustomPage = "content/" + this.props.item.link.content;
        // On check si l'url de la page contient content/[la location de l'item]  si oui on ajoute la classname selected
        // Ajout d'un check plus précis, dans le cas où les chemins peuvent se ressembler. bug #44559
        const pathMatch = this.props.location?.pathname?.match(`${isCustomPage}$`);
        if (this.props.visualConfiguration && this.props.visualConfiguration.appHeader
            && this.props.visualConfiguration.appHeader.activeColor && pathMatch && pathMatch.length) {
            menuitemclass += " selected bloc-accent";
        }

        const title = translateBag(this.props.i18n, this.props.item.label);
        const ariaLabel = translateBag(this.props.i18n, this.props.item.ariaLabel) || title;
        const hasSubItems = this.props.item?.items?.length;
        let subItemsIcon;
        let role = this.props.item?.role || "link";
        if (hasSubItems && this.props.showSubMenuIcon) {
            role = "link";
            if (this.state.showSubMenu) {
                subItemsIcon = <div className="sub-menu-button">
                    <button
                        id="icon"
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="true"
                    >
                        <i className="inwink-chevron-up" />
                    </button>
                </div>;
            } else {
                subItemsIcon = <div className="sub-menu-button">
                    <button
                        id="icon"
                        aria-haspopup="true"
                        aria-expanded="false"
                        type="button"
                    >
                        <i className="inwink-chevron-down" />
                    </button>
                </div>;
            }
        }

        return <>
            <AppLink
                className={menuitemclass}
                enableActiveLink
                user={this.props.user}
                theme={this.props.visualConfiguration}
                link={hasSubItems ? null : this.props.item.link}
                style={styleObject(menuitemstyle)}
                i18n={this.props.i18n}
                role={role}
                ariaLabel={ariaLabel}
                tabIndex={this.props.tabIndex}
                title={showByDateTitle}
                location={this.props.location}
                onClick={hasSubItems ? () => this.toggleSubMenu() : this.props.onClick}
            >
                {icon}
                <span><span ref={this.container} />
                    {title}
                </span>

                <div
                    className="sub-menu-button"
                >
                    {subItemsIcon}
                </div>
            </AppLink>
            {hasSubItems ? <SubMenuItemsSection
                {...this.props}
                close={() => this.toggleSubMenu(false)}
                isVisible={this.state.showSubMenu}
                items={this.props.item?.items}
                template={null}
                theme={null}
            /> : null }
        </>;
    }

    render() {
        const i18n = this.props.i18nHelper;
        let showByDateTitle;
        let linkto; let wndTarget; const
            onClick = this.props.onClick;
        let menuitemclass = "iconlink headline " + (this.props.className || "") + (this.props.item.className || "");
        const menuitemstyle = assign({}, this.props.style, this.props.item.style);
        const icon = this.props.item.icon && this.props.item.icon.className
            ? <i className={"icon " + this.props.item.icon.className} /> : undefined;

        if (this.props.item.showblocbydate && InWinkPreview) {
            const filterType = checkIfShownByDate(this.props.item, this.props.event, this.props.datacontext, true);
            if (filterType) {
                if ((filterType as any).dates) {
                    menuitemclass += " hidden";
                    showByDateTitle = i18n.translate("actions.filterbydate.dates", { dates: (filterType as any).dates });
                } else if (typeof filterType === "string") {
                    menuitemclass += " hidden";
                    showByDateTitle = i18n.translate(`actions.filterbydate.${filterType}`);
                }
            }
        }

        if (this.props.item && this.props.item.link) {
            return this.renderLink(menuitemclass, showByDateTitle, menuitemstyle, icon);
        }

        const LinkComponent = Link as any; // pour feinter typescript

        return <LinkComponent
            className={menuitemclass}
            target={wndTarget}
            to={linkto}
            role={this.props.item.role}
            style={menuitemstyle}
            title={showByDateTitle}
            onClick={onClick}
        >
            {icon}
            {translateBag(this.props.i18n, this.props.item.label)}
        </LinkComponent>;
    }
}

function EmptyLink() {
    return <a />;
}
